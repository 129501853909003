import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardlayoutModule } from './dashboardlayout/dashboardlayout.module';
// import { MasterModule } from './master/master.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { RootStoreModule } from './root-store';
import { AppStoreModule } from './modal/app/app-store.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import {MatSortModule} from '@angular/material/sort';
import { HomedashboardModule } from './homedashboard/homedashboard.module';
import { OrderByPipe } from './services/order-by.pipe';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    OrderByPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardlayoutModule,
    // MasterModule, 
    ToastrModule.forRoot(),
    RootStoreModule,
    AppStoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSortModule,
    HomedashboardModule,
    NgxUiLoaderModule,
    NgxMaterialTimepickerModule,
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports:[OrderByPipe],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
