import { Component, HostListener, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-dashmaster',
  templateUrl: './dashmaster.component.html',
  styleUrls: ['./dashmaster.component.scss'],
  providers:[NgxUiLoaderService],
  host: {
    "(window:resize)":"onWindowResize($event)"
  }
})
export class DashmasterComponent implements OnInit {
  isSticky: boolean = false;
  sidenav:any;
  user_id:any;
  role_id:any;
  user:any;
  urlbar:any;

  isMobile: boolean = false;
  width:number = window.innerWidth;
  mobileWidth:number  = 500;

  constructor(private router:Router,public ngxloader:NgxUiLoaderService,private cds:CommonService) {
    this.user = JSON.parse(localStorage.getItem("userAgency_JustMyTrip"));
    this.urlbar = this.router.url;
    console.log("urll :::",this.urlbar)
    
//console.log("user ::::::ASD:::::",this.user)
    if(this.user != null){ 
      this.user_id = this.user.id;
      this.role_id = this.user.role_id;
    }
    
//console.log("user ::::::ASD:::::",this.role_id)
   }
  
   onActivate(val){
     var data =  val;
    window.scroll(data,0);
   }
  ngOnInit():void {  
    if(this.user && this.urlbar == '/home-sector'){
      this.router.navigateByUrl('/offerin')
    }
    this.isMobile = this.width < this.mobileWidth;
    window.addEventListener('scroll', this.scroll, true);

  }
  gotoinventory(val){
    // [routerLink]="['/master/inventory-one-way']"
    var val1='dashboard'
    this.ngxloader.start();

    this.router.navigateByUrl(val);
  }

  onWindowResize(event) {
    // 
//console.log("sssssss",event);
    
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
    if(this.isMobile = this.width < this.mobileWidth)
      {
        this.isMobile = true; 
        this.isExpanded = false;
      }
      else{
        this.isMobile = false; 
        this.isExpanded = true;
      }
}
  // @ViewChild('sidenav') sidenav: MatSidenav;
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("tokenAgency_JustMyTrip");
    localStorage.removeItem("userAgency_JustMyTrip");
    this.router.navigateByUrl('/offerin')
  }
  isExpanded = true;
  showSubmenu: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
    
  }


  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   this.isSticky = window.pageYOffset >= 20;
  // }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  
  scroll = (event): void => {
    this.isSticky = window.pageYOffset >= 20;
  };
 
}
